<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item"><a href=""> Quản lý Tỉnh/Thành phố </a></li>
    </ol>
    <div class="container-fluid">
      <div class="col-sm-10">
        <div class="form-group">
          <router-link to="/admin/location/add" class="btn btn-sm btn-primary">
            <i class="fas fa-plus"></i>
            Thêm mới Tỉnh/Thành phố
          </router-link>
        </div>
      </div>

      <div>
        <form method="get"  @submit.prevent="onSearch"  id="my_search">
          <div class="row">
            <div class="col-sm-5 mb-3">
              <input
                type="text"
                name="name"
                class="form-control"
                v-model="formSearch.name"
                autocomplete="off"
                placeholder="Nhập tên..."

                
              />

              <button class="btn btn-sm btn-warning mt-3" type="submit">
                <i class="fa fa-search-plus"></i> TÌM KIẾM
              </button>
            </div>
             
          </div>
        </form>

      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header" v-if="is_loading">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <Skeleton width="40px" height="40"></Skeleton>
              </SkeletonTheme>
            </div>
            <div class="card-header" v-else>
              <i class="fa fa-align-justify" v-if="mydata != null"></i> Quản lý
              Tỉnh/Thành phố : ( {{ page_size }} )
            </div>
            <div class="card-block">
              <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <table class="table table-sm">
                  <thead>
                    <tr>
                       
                      <th>Tỉnh/Thành Phố</th>
                      <th>Mã </th>
                     
                      <th>Tuỳ chọn</th>

                    </tr>
                  </thead>
                  <tbody v-if="is_loading">
                    <tr v-for="(el, k) in 10" :key="k">
                      <td v-for="kk in 3" :key="kk">
                        <Skeleton height="40px"></Skeleton>
                      </td>
                      
                    </tr>
                  </tbody>
                  <tbody v-else>




                    <tr
                      v-for="(value, key) in paginationslice(mydata, 30, page)"
                      :Key="key"
                    >
                       <td>
                      
                          {{value.name}}
                          
                      </td>
                      
                      <td >
                       {{ value.code  }} 
                      </td>
                      
                      <td>
                        <router-link
                          class="btn btn-sm btn-success"
                          v-bind:to="'/admin/location/edit/' + value.id"
                        >
                          <i class="fa fa-edit"></i>
                          Sửa
                        </router-link>
                        <div
                          class="btn btn-sm btn-danger click_remove"
                          @click="removeElement(value.id)"
                        >
                          <i class="fa fa-remove"></i> Xoá
                        </div>

                      
                      </td>

                      
                    </tr>
                  </tbody>
                </table>

               <nav v-if="mydata != null && mydata.length > 30">
                  <VuePagination
                    :page-count="Math.ceil(mydata.length / 30)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  >
                  </VuePagination>
                </nav>
              </SkeletonTheme>
            </div>
          </div>
        </div>
        <!--/.col-->
      </div>
    </div>
  </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
import axios from "axios";

export default {
  
  data() {
    return {
      is_loading: true,
      form: {},
      mydata: null,
      main:null,
      search: "",
      page: 1,
      id_load: "",
      mycate:null,
      my_url: window.my_url,

      page_size:0,
      is_remove:[],

      isLogin:null,
      formSearch:{
         name:"",
          cate:""
      },
      language: window.my_header.language,
      role:2
    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination,
  },
  created(){  
      let isLogin= this.$session.get("isLogin");
      this.isLogin=isLogin.permistions.split(",");

      let Customer= this.$session.get("Customer");
      this.role=Customer.isRole;
  },
  mounted() {

   let p2=  axios
      .get(window.my_api + "api/location/get-locations", {
        headers: window.my_header
      })
      .then((res) => {
        if (res.status == 200) {
          this.mydata=this.main  = res.data.data;
          this.page_size=this.mydata.length;
            
          if(this.$route.query.name !=undefined){
              this.formSearch.name=this.$route.query.name;
              this.mydata=this.main.filter(n => n.name.toUpperCase().search(this.$route.query.name.toUpperCase()) > -1 || n.slug.toUpperCase().search(this.$route.query.name.toUpperCase())> -1);
          }
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

      Promise.all([p2])
             .then( ()=>{
                this.is_loading = false;
             });
  },
  methods: {
    onSearch:function(){

      
       

          this.$router.push({query:{'name':this.formSearch.name}});

           this.mydata=this.main.filter(n => n.name.toUpperCase().search(this.formSearch.name.toUpperCase()) > -1 || n.slug.toUpperCase().search(this.formSearch.name.toUpperCase())> -1);
    },
     changePage(e) {
      this.page = e;
    },
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },


    
    removeElement(id) {
      this.$swal
        .fire({
          title: "Bạn có muốn xóa?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.id_load = id;
            axios
              .delete(window.my_api + "api/location/remove-location", {
                data: { locationIds: [id] },
                headers: window.my_header
              })
              
              .then((res) => {
                if (res.status == 200) {
                  this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Xóa thành công.",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.mydata = this.mydata.filter((el) => el.id !== this.id_load);
                } else {
                  alert(
                    "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                  );
                }
                this.id_load = "";
              });
          }
        });
    },
  },
};
</script>








